<template>
  <v-app-bar
    app
    class="white px-md-5"
    elevation="0"
    :height="$vuetify.breakpoint.mdAndUp ? '100px' : '70px'"
  >
    <v-container>
      <div class="d-none d-md-block">
        <v-row align="center">
          <div class="d-flex align-center">
            <v-img
              alt="Logo"
              class="shrink mr-2"
              contain
              src="@/assets/logo.png"
              transition="scale-transition"
              width="210"
            />
          </div>
          <div class="mr-2 subtitle-2">

          </div>
          <v-spacer></v-spacer>
          <div>
            <router-link :to="'/stats'" class="menu-item"
              >Stats</router-link
            >
            <router-link :to="'/device'" class="menu-item"
              >Kiosks</router-link
            >
            <router-link :to="'/adminusers'" class="menu-item"
              >User managment</router-link
            >
            
          </div><v-spacer ></v-spacer>
          <v-btn
            color="teal accent-3"
            dark
            outlined
            rounded
            :to="{ name: 'login' }"
          >
            Logout
          </v-btn>
        </v-row>
      </div>

      <v-row align="center" class="d-md-none my-0 justify-space-between">
        <v-menu right bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <template>
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
          </template>

          <v-list>
            <v-list-item :to="{ name: 'stats' }" dense>
              <v-list-item-title class="subtitle-2">Stats</v-list-item-title>
            </v-list-item>
            <v-list-item :to="'/device'" dense>
              <v-list-item-title class="subtitle-2">Kiosks</v-list-item-title>
            </v-list-item>
            <v-list-item :to="'/adminusers'" dense>
              <v-list-item-title class="subtitle-2">User managment</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="d-flex align-center">
          <router-link to="/">
            <v-img
              alt="Logo"
              class="shrink mr-2"
              contain
              src="@/assets/logo.png"
              transition="scale-transition"
              width="115"
            />
          </router-link>
        </div>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import axios from "axios";

export default {
  name: "app-header",

  data: () => {
    return {

    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {

    signOut() {
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.menu-item {
  text-decoration: none !important;
  padding: 5px 10px;
  color: var(--v-secondary-base);
  font-size: 14px;

  &:hover {
    color: var(--v-primary-base);
  }

  &.router-link-exact-active {
    color: var(--v-gold_4-base);
  }

  &:not(:last-child) {
    border-right: 1px solid var(--v-primary-base);
  }
}
</style>
<template>
  <v-container>
    <div>
      <v-row>
        <v-col cols="12">
          <v-card class="pa-3 teal accent-3">
            <div class="d-flex justify-space-between align-center">
              <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
                Global Stats
              </div>
              <v-btn
                outlined
                dark
                small
                v-bind="attrs"
                v-on="on"
                @click="dialog = true"
              >
                <v-icon> mdi-cog-outline </v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
        <v-col md="3" cols="12">
          <v-card class="my-2 border-left-4">
            <div>
              <div>
                <v-card-subtitle class="pb-0"> Listed Device </v-card-subtitle>
                <v-card-title class="text-h6 gold_4--text">
                  {{ this.stats.length }}
                </v-card-title>
              </div>
            </div>
          </v-card>

          <v-card class="my-2 border-left-1">
            <div>
              <div>
                <v-card-title class="text-h6">
                  Total Time:
                  <p>
                    Totale di accensione (ore di lavoro di R4):
                    {{
                      (usagestatsGlobal["R4"] / 1000 / 60 / 60).toFixed(2)
                    }}
                    ore
                  </p>
                  <p>
                    Tempo totale USCITA 1 (R1):
                    {{
                      (usagestatsGlobal["R1"] / 1000 / 60 / 60).toFixed(2)
                    }}
                    ore
                  </p>
                  <p>
                    Tempo totale USCITA 2 (R2):
                    {{
                      (usagestatsGlobal["R2"] / 1000 / 60 / 60).toFixed(2)
                    }}
                    ore
                  </p>
                  <p>
                    Tempo totale USCITA 3 (R3):
                    {{
                      (usagestatsGlobal["R3"] / 1000 / 60 / 60).toFixed(2)
                    }}
                    ore
                  </p>
                </v-card-title>
              </div>
            </div>
          </v-card>

          <!-- <v-card class="my-2 border-left-2">
            <div class="pa-4 text-center">
              <v-card-subtitle class="pb-0"> Total Data: </v-card-subtitle>
              <v-card-title class="text-h6 gold_4--text text-center">
 
              </v-card-title>
            </div>
          </v-card>-->
        </v-col>
        <v-col md="9" cols="12" class="my-2">
          <v-card>
            <div id="chart">
           <!--    <apexchart
                type="area"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>-->
              <v-row class="pa-5">
                <v-autocomplete
                  v-model="macs"
                  :items="stats"
                  :item-text="getItemText"
                  chips
                  label="Device"
                  multiple
                  return-object
                  @change="getHours()"
                  outlined
                ></v-autocomplete>
                 <!-- <v-select multiple 
            label="Relay" outlined v-model="message" :items="[1, 2, 3,4]"></v-select>-->
              </v-row>
            </div> </v-card
          >
          <v-card class="pa-5">
            <p>
              Totale di accensione (ore di lavoro di R4):
              {{ (usageStats["R4"] / 1000 / 60 / 60).toFixed(2) }} ore
            </p>
        <!--    <p>Tempo totale Trattamento MANI: {{ (usageStats["Mani"] / 1000 / 60 / 60).toFixed(2) }} ore</p>
            <p>Tempo totale Trattamento VISO: {{ (usageStats["Viso"] / 1000 / 60 / 60).toFixed(2) }} ore</p>
            <p>Tempo totale Trattamento PIEDI: {{ (usageStats["Piedi"] / 1000 / 60 / 60).toFixed(2) }} ore</p>-->
            <p>
              Tempo totale USCITA 1 (R1):
              {{ (usageStats["R1"] / 1000 / 60 / 60).toFixed(2) }} ore
            </p>
            <p>
              Tempo totale USCITA 2 (R2):
              {{ (usageStats["R2"] / 1000 / 60 / 60).toFixed(2) }} ore
            </p>
            <p>
              Tempo totale USCITA 3 (R3):
              {{ (usageStats["R3"] / 1000 / 60 / 60).toFixed(2) }} ore
            </p>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import {
  API_BASE_URL
} from "../constants";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

// Requiring the lodash library
const _ = require("lodash");

export default {
  setup() {},
  data() {
    return {
      token: JSON.parse(localStorage.getItem("user")),
      stats: [],
      macs: [],
      usageStats : {
        R1: 0,
        R2: 0,
        R3: 0,
        R4: 0,
        Mani: 0,
        Viso: 0,
        Piedi: 0,
      },
      usagestatsGlobal: {
        R1: 0,
        R2: 0,
        R3: 0,
        R4: 0,
        Mani: 0,
        Viso: 0,
        Piedi: 0,
      },
      series: [],
      sumResult: [],
      message: "",
      messages: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          tickAmount: 8,
          min: new Date("01/01/2022").getTime(),
          max: new Date("04/20/2022").getTime(),
          labels: {
            rotate: -15,
            rotateAlways: true,
            formatter: function (val, timestamp) {
              return moment(new Date(timestamp)).format("DD MMM YYYY");
            },
          },
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
    };
  },
  mounted() {
    this.socket.on("MESSAGE", (data) => {
      this.messages = [...this.messages, data];
      // you can also do this.messages.push(data)
    });
  },
  computed: {},

  beforeMount() {
    this.getStats();
    this.getglobalStats();
  },
  methods: {
    getItemText(item) {
    return `${item.mac} ${item.name}`;
},
    sendMessage(e) {
      e.preventDefault();
      this.socket.emit("SEND_MESSAGE", {
        mac: this.user,
        relay: this.message,
        status: this.status,
      });
      this.message = "";
    },
    getMac() {
      axios
        .get(`${API_BASE_URL}/kiosk`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.stats = resp.data;
        });
    },
    getStats() {
      axios
        .get(`${API_BASE_URL}/stats`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.getMac();
          let response = resp.data;
          let res = _.groupBy(response, function (b) {
            return b.mac;
          });
          //this.stats = res;
          Object.keys(res).forEach((key) => {
            console.log(key, res[key]);
            var relay = [];
            var timeframe = [];
            res[key].forEach((element) => {
              relay.push(element.status);
              timeframe.push(element.created_at);
            });
            this.series.push({ name: key, data: relay });
            this.chartOptions.xaxis.categories = timeframe;
          });
        });
    },
    getglobalStats() {
      this.sumResult = [];
      this.usagestatsGlobal = {
        R1: 0,
        R2: 0,
        R3: 0,
        R4: 0,
        Mani: 0,
        Viso: 0,
        Piedi: 0,
      };
      var relayName = ["", "R1", "R2", "R3", "R4"];
      axios
        .get(`${API_BASE_URL}/stats`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          resp = resp.data;
          let res = _.groupBy(resp, function (b) {
            return b.relay;
          });
          let groupRelay = [];
          for (let index in res) {
            groupRelay.push(res[index]);
          }
          console.log("resp", groupRelay);
          groupRelay.forEach((relay) => {
            for (let index = 1; index < relay.length; index++) {
              const element = relay[index];
              console.log("value", element, index);
              if (element.status == "OFF") {
                console.log("elements", element);
                var d1 = new Date(element.created_at);
                var d2 = new Date(relay[index - 1].created_at);
                var diff = Math.abs(d1 - d2);
                console.log(diff);
                this.usagestatsGlobal[relayName[element.relay]] += diff;
              }
            }
          });
          this.sumResult = resp;
        });
    },

    getHours() {
      this.sumResult = [];
      this.usageStats = {
        R1: 0,
        R2: 0,
        R3: 0,
        R4: 0,
        Mani: 0,
        Viso: 0,
        Piedi: 0,
      };
      var relayName = ["", "R1", "R2", "R3", "R4"];
      this.macs.forEach((element) => {
        axios
          .get(`${API_BASE_URL}/stats/${element.mac}`, {
            headers: {
              accept: "application/json",
              "x-access-token": this.token.accessToken,
            },
          })
          .then((resp) => {
            resp = resp.data;
            let res = _.groupBy(resp, function (b) {
              return b.relay;
            });
            let groupRelay = [];
            for (let index in res) {
              groupRelay.push(res[index]);
            }
            groupRelay.forEach((relay) => {
              for (let index = 1; index < relay.length; index++) {
                const element = relay[index];
                console.log(element);
                if (element.status == "OFF") {
                  var d1 = new Date(element.created_at);
                  var d2 = new Date(relay[index - 1].created_at);
                  var diff = Math.abs(d1 - d2);
                  this.usageStats[relayName[element.relay]] += diff;
                }
              }
            });
            this.sumResult.push(resp);
          });
      });
    },
  },
};
</script>
<style>
.capitalize {
  text-transform: capitalize;
}
.style-1 {
  background-color: #ff5252;
}
.style-2 {
  background-color: rgb(255, 255, 255);
}

.v-card__title {
  word-break: break-word !important;
}
.v-application .my-5 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
</style>
<template>
  <v-row class="ma-0">
    <v-col
      sm="6"
      cols="12"
      class="teal accent-3 pa-7 d-flex align-items-center hv-sm-100"
    >
    </v-col>
    <v-col sm="6" cols="12" class="px-7 py-3 hv-sm-100 d-flex">
      <v-card
        class="ma-auto"
        elevation="0"
        :width="$vuetify.breakpoint.smAndUp ? '70%' : '85%'"
      >
        <v-form ref="form" class="text-center" @submit="login">
          <div class="headline secondary--text font-weight-bold my-2">
            Login
          </div>
          <v-text-field
            v-model="form.email"
            label="Username"
            type="email"
            class="my-5"
            required
          >
          </v-text-field>
          <v-text-field
            v-model="form.password"
            label="Password*"
            type="password"
            class="my-5"
            required
            v-on:keyup.enter="login"
            :rules="rules.requiredRules"
          >
          </v-text-field>
          <v-btn class="teal accent-3 white--text my-3" @click="login"
            >login</v-btn
          >
          <v-alert
            dense
            outlined
            color="error"
            class="my-5"
            dismissible
            v-if="error"
          >
            {{ error }}
          </v-alert>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "../constants";

const API_URL = `${API_BASE_URL}/api/auth/`;
export default {
  name: "App",
  data: () => ({
    form: {
      email: "",
      password: "",
      two_fa:  ""
    },
    twofactor: false,
    twofactorError: false,
    twofactorErrorMsg: '',
    error: null,
    rules: {
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    },
  }),
  methods: {
    twofactorSubmit(){
      if(!this.form.two_fa){
        this.twofactorError = true;
        this.twofactorErrorMsg = 'Please enter 2-FA code.'
        return false;
      }
      this.login();
    },
    login() {
      this.error = null;
      axios
        .post(API_URL + "signin", {
          username: this.form.email,
          password: this.form.password,
          two_fa: this.form.two_fa
        })
        .then((response) => {
          if(response.data.status == 1){
            // alert('2FA sent open modal.');
            this.twofactor = true;
            return false;
          }
          if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data));
            this.$router.replace({ name: "stats" });
          }

          return response.data;
        })
        .catch(error  => {
            console.log('inisde catch');
            if (error.response) {
                if(error.response.data.status == 0){
                  this.twofactorErrorMsg = error.response.data.message;
                  this.twofactorError = true;
                }else{
                  this.error = error.response.data.message;
                }
            }
        });
    },

    setcookie() {
      this.$cookies.set("uid", this.user.data.uid);
    },
  },
};
</script>
<style>
.contact-icon {
  background: white;
  border-radius: 50%;
  padding: 10px;
  margin: 20px;
}
</style>
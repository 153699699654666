import Vue from 'vue'
import Router from 'vue-router'
import stats from './views/stats.vue'
import device from './views/device.vue'
import DashboardLayout from './layout/dashboardLayout.vue'
import AuthLayout from './layout/authLayout.vue'
import login from './views/login.vue'
import adminusers from './views/adminuser.vue'
import register from './views/register.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: 'account',
            component: DashboardLayout,
            children: [
                {
                    path: '/',
                    name: 'login',
                    redirect: 'login',
                    component: login
                },
                {
                    path: '/device',
                    name: 'device',
                    component: device,
                },
                {
                    path: '/stats',
                    name: 'stats',
                    component: stats,
                },
                {
                    path: '/adminusers',
                    name: 'adminusers',
                    component: adminusers,
                },
                {
                    path: '/register',
                    name: 'register',
                    component: register
                }
            ]
        },
        {
            path: '/',
            redirect: 'login',
            component: AuthLayout,
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: login
                }

            ]
        }    
    ]
})
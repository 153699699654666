<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 teal accent-3">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              User Managment
            </div>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              dark
              small
              v-bind="attrs"
              v-on="on"
              :to="'/register'"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-dialog
      v-model="restore"
      max-width="500"
    >
      <v-card class="pa-3" style="height: 100%" min-height="200px">
        <v-card-title class="text-h5">
          Set New Password for {{user.username}} 
        </v-card-title>
                  <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Enter new password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="restore = false"
          >
            Close
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click=restoreUser(user)
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            User
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <div class="pa-2">
            <v-data-table
              :headers="headers"
              :items="requestLog"
              item-key="id"
              class="elevation-1"
              :search="search"
            >
              <template v-slot:item.append> </template>
               <template v-slot:item.status="{ item }">
                <v-btn v-if="item.is_admin_active == 0" color="success" @click="makeAdminActiveOrInactive(item.id, item.is_admin_active)"
                  >Set Active</v-btn
                >
                <v-btn v-if="item.is_admin_active == 1 && isSuperAdmin == 1" color="red" @click="makeAdminActiveOrInactive(item.id, item.is_admin_active)"
                  >Set InActive</v-btn
                >
                <span v-if="item.is_admin_active == 1 && isSuperAdmin == 0">Active</span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn color="success" @click="restorePass(item)"
                  >Restore Password</v-btn
                >
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "../constants";
import VueCookies from "vue-cookies";

export default {
  setup() {},
  data() {
    return {
        show1: false,
        password: '',
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
      items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
      message: "",
      amount: 0,
      dialog: false,
      hotspotDetails: [],
      user:{},
      hotspotTotal: [],
      url: "",
      mapurl: "",
      token: JSON.parse(localStorage.getItem("user")),
      start: "",
      today: "",
      restore:false,
      search: "",
      uid: "",
      oracleprice: null,
      installation: "",
      requestLog: [],
      maxearn: 0,
      attrs: '',
      on: '',
      isSuperAdmin: 0
    };
  },
  computed: {
    headers() {
      return [
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
        { text: "Created At", value: "createdAt" },
        { text: "Last Update", value: "updatedAt" },
        { text: "Status", value: "status", sortable: false, align: "center" },
        { text: "Actions", value: "action", sortable: false, align: "center" },
      ];
    },
  },
  beforeMount() {
    this.getRequest()
    let userObject = JSON.parse(localStorage.getItem('user'));
    console.log('userObject', userObject);
    this.isSuperAdmin = userObject.is_super_admin;

  },

  methods: {
    restorePass(item){
      this.restore = true,
      this.user = item
    },
    restoreUser(item){  
      this.restore = false
      let payload = {
        password: this.password,
      };
            axios
        .put(`${API_BASE_URL}/restore/${item.id}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((res) => {
          this.password = ""
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getRequest() {
      axios
        .get(`${API_BASE_URL}/adminusers`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.requestLog = resp.data;
        });
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },

    makeAdminActiveOrInactive(userID, status){
      let changeStatus = status == 0 ? 1 : 0;
      let statusName = changeStatus == 0 ? 'Inactive' : 'active';
      if(confirm(`Do you really want to set this user ${statusName}?`)){
        axios
        .get(`${API_BASE_URL}/makeAdminActiveOrInactive/${changeStatus}/${userID}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.getRequest();
        });
      }
    }
  },
};
</script>
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 teal accent-3">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Kiosk
            </div>
          </div>
        </v-card>
      </v-col>
      <v-dialog v-model="edit" max-width="500">
        <v-card class="pa-3" style="height: 100%" min-height="200px">
          <v-card-title class="text-h5"> Edit Kiosk </v-card-title>
          <v-text-field v-model="kioskDet.name" label="Nome"> </v-text-field>
          <v-text-field v-model="kioskDet.mac" label="MAC"> </v-text-field>
          <v-text-field v-model="kioskDetJSON.ragsoc" label="Ragione Sociale">
          </v-text-field>
          <v-text-field v-model="kioskDet.logoPath" label="Percorso logo">
          </v-text-field>
          <div v-for="(value, index) in kioskDetJSON.zona" :key="index">
            <v-text-field v-model="kioskDetJSON.zona[index].label" label="Zona">
            </v-text-field>
            <v-text-field
              v-model="kioskDetJSON.zona[index].timer"
              label="Tempo in Secondi"
            >
            </v-text-field>
            <div
              v-for="(value, index) in kioskDetJSON.zona[index].tipologia"
              :key="index"
            >
              <v-text-field
                v-model="kioskDetJSON.zona[index].tipologia[index].label"
                label="Label"
              >
              </v-text-field>
            </div>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="this.edit = false">
              Close
            </v-btn>

            <v-btn
              color="green darken-1"
              text
              @click="saveKiosk(kioskDet, kioskDetJSON)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="remoteConnection" max-width="500">
        <v-card>
          <h5>Remote Device Control</h5>
          <form @submit.prevent="sendMessage">
            <div class="form-group">
              <label for="user">mac :</label>
              <input type="text" v-model="user" class="form-control" />
            </div>
            <div class="gorm-group pb-3">
              <label for="message">relay:</label>
              <v-select v-model="message" :items="[1, 2, 3]"></v-select>
            </div>
            <div class="gorm-group pb-3">
              <label for="message">status:</label>
              <v-select v-model="status" :items="['ON', 'OFF']"></v-select>
            </div>
            <button type="submit" class="btn btn-success">Send</button>
          </form>
        </v-card>
      </v-dialog>

      <v-dialog v-model="editDevice" max-width="700">
        <v-card class="pa-3" style="height: 100%" min-height="200px">
          <v-card-title class="text-h5"> Edit Device </v-card-title>
          Dati
          <v-text-field v-model="kioskDet.name" label="Nome Device">
          </v-text-field>
          <v-text-field v-model="kioskDet.mac" label="MAC"> </v-text-field>
          <v-text-field v-model="kioskDetJSON.ragsoc" label="Ragione Sociale">
          </v-text-field>
          <v-text-field v-model="kioskDetJSON.indirizzo" label="Indirizzo">
          </v-text-field>
          <v-text-field v-model="kioskDetJSON.citta" label="Città">
          </v-text-field>
          <v-text-field v-model="kioskDetJSON.email" label="Email">
          </v-text-field>
          Accesso
          <v-text-field v-model="kioskDetJSON.user" label="User">
          </v-text-field>
          <v-text-field v-model="kioskDetJSON.password" label="Password">
          </v-text-field>
          Alert
          <v-text-field v-model="kioskDetJSON.R1" label="Limite Ore Relay 1">
          </v-text-field>
          <v-text-field v-model="kioskDetJSON.R2" label="Limite Ore Relay 2">
          </v-text-field>
          <v-text-field v-model="kioskDetJSON.R3" label="Limite Ore Relay 3">
          </v-text-field>
          <v-text-field v-model="kioskDetJSON.R4" label="Limite Ore Relay 4">
          </v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="editDevice = false">
              Close
            </v-btn>

            <v-btn
              color="green darken-1"
              text
              @click="saveKiosk(kioskDet, kioskDetJSON)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="remoteConnection" max-width="500">
        <v-card class="pa-3" style="height: 100%" min-height="200px">
          <form @submit.prevent="sendMessage">
            <div class="gorm-group">
              <label for="user">mac :</label>
              <input type="text" v-model="user" class="form-control" />
            </div>
            <div class="gorm-group pb-3">
              <label for="message">relay:</label>
              <v-select v-model="message" :items="[1, 2, 3]"></v-select>
            </div>
            <div class="gorm-group pb-3">
              <label for="message">status:</label>
              <v-select v-model="status" :items="['ON', 'OFF']"></v-select>
            </div>
            <button type="submit" class="btn btn-success">Send</button>
          </form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="logsPopup" max-width="700">
        <v-card>
          <v-card-title>
            Usage Logs
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search2"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers2"
            :items="logs"
            :search="search"
          ></v-data-table>
        </v-card>
      </v-dialog>
      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Kiosk List
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <div class="pa-2">
            <v-data-table
              :headers="headers"
              :items="requestLog"
              item-key="id"
              class="elevation-1"
              :search="search"
            >
              <template v-slot:item.alert="{ item }">
                1<v-icon :color="item.R1">mdi-alert</v-icon>
                2<v-icon :color="item.R2">mdi-alert</v-icon>
                3<v-icon :color="item.R3">mdi-alert</v-icon>
                4<v-icon :color="item.R4">mdi-alert</v-icon>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  fab
                  dark
                  small
                  color="green accent-4"
                  @click="remoteConn(item)"
                  ><v-icon dark>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  color="yellow accent-4"
                  @click="editKiosk(item.mac)"
                  ><v-icon dark>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  color="orange accent-4"
                  @click="openLogs(item)"
                  ><v-icon dark> mdi-chart-areaspline-variant </v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  small
                  color="red accent-4"
                  @click="deleteKiosk(item.id)"
                  ><v-icon dark> mdi-delete </v-icon>
                </v-btn>
                <!--<v-btn fab dark small color="green" @click="exportdata(item)"
                  ><v-icon dark> mdi-microsoft-excel </v-icon></v-btn
                >-->
              </template>
            </v-data-table>
          </div>
        </v-card>

        <v-snackbar v-model="snackbar" :timeout="timeout">
          Kiosk deleted successfully.
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import { API_BASE_URL } from "../constants";
import io from "socket.io-client";
// Requiring the lodash library
const _ = require("lodash");

export default {
  setup() {},
  data() {
    return {
      socket: io("https://api.connectingthings.tech"),
      user: "",
      status: "",
      message: "",
      search2: "",
      amount: 0,
      edit: false,
      logsPopup: false,
      editDevice: false,
      kioskDet: {},
      kioskDetJSON: {},
      hotspotTotal: [],
      remoteConnection: false,
      url: "",
      mapurl: "",
      token: JSON.parse(localStorage.getItem("user")),
      start: "",
      today: "",
      search: "",
      uid: "",
      logs: [],
      oracleprice: null,
      installation: "",
      templist:[],
      requestLog: [],
      snackbar: false,
      timeout: 4000,
      attrs: "",
    };
  },
  computed: {
    headers() {
      return [
        { text: "Cliente", value: "name" },
        {
          text: "Mac Address",
          align: "start",
          sortable: false,
          value: "mac",
        },
                {
          text: "Last Seen Online",
          align: "start",
          sortable: false,
          value: "online",
        },
        {
          text: "Created Date",
          value: "created_at",
          sortable: true,
          align: "center",
        },
        { text: "Last Update", value: "updated_at", align: "center" },
        { text: "Alert", value: "alert", align: "center" },
        { text: "Firmware Version", value: "version", align: "center" },
        { text: "Actions", value: "action", sortable: false, align: "center" },
      ];
    },
    headers2() {
      return [
        { text: "mac", value: "mac", align: "center" },
        { text: "Relay", value: "relay" },
        {
          text: "Created Date",
          value: "created_at",
          sortable: true,
          align: "center",
        },
        { text: "Status", value: "status", align: "center" },
      ];
    },
  },

  beforeMount() {
    this.getRequest();
  },
  mounted() {
    this.socket.on("MESSAGE", (data) => {
      this.messages = [...this.messages, data];
      // you can also do this.messages.push(data)
    });
  },

  methods: {
    remoteConn(item) {
      console.log(item);
      this.user = item.mac;
      this.remoteConnection = true;
    },
    sendMessage(e) {
      e.preventDefault();
      this.socket.emit("SEND_MESSAGE", {
        mac: this.user,
        relay: this.message,
        status: this.status,
      });
      this.message = "";
    },

    openLogs(item) {
      axios
        .get(`${API_BASE_URL}/stats/${item.mac}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.logsPopup = true;
          this.logs = resp.data;
        });
    },

    editKiosk(id) {
      axios
        .get(`${API_BASE_URL}/kiosk/${id}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.editDevice = true;
          this.kioskDet = resp.data[0];
          this.kioskDetJSON = JSON.parse(this.kioskDet.details);
        });
    },

    editConfig(id) {
      axios
        .get(`${API_BASE_URL}/kiosk/${id}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.kioskDet = resp.data[0];
          this.kioskDetJSON = JSON.parse(this.kioskDet.json);
          this.edit = true;
        });
    },

    calStats(element,index){
                  console.log(element);
            var alertLogs = JSON.parse(element.details);
            var usageStats = {
              R1: 0,
              R2: 0,
              R3: 0,
              R4: 0,
              Mani: 0,
              Viso: 0,
              Piedi: 0,
            };
            var relayName = ["", "R1", "R2", "R3", "R4"];
            axios
              .get(`${API_BASE_URL}/stats/${element.mac}`, {
                headers: {
                  accept: "application/json",
                  "x-access-token": this.token.accessToken,
                },
              })
              .then((stats) => {
                this.templist[index].online = stats.data[stats.data.length - 1].created_at;
                let res = _.groupBy(stats.data, function (b) {
                  return b.relay;
                });
                let groupRelay = [];
                for (let elements in res) {
                  groupRelay.push(res[elements]);
                }
                groupRelay.forEach((relay) => {
                  for (let index2 = 1; index2 < relay.length; index2++) {
                    const relayDetails = relay[index2];
                    if (relayDetails.status == "OFF") {
                      var d1 = new Date(relayDetails.created_at);
                      var d2 = new Date(relay[index2 - 1].created_at);
                      var diff = Math.abs(d1 - d2);
                      usageStats[relayName[relayDetails.relay]] += (diff/ 1000 / 60 / 60);
                    }
                  }
                });
              console.log(usageStats,alertLogs)
            if ("R1" in alertLogs) {
              if (alertLogs["R1"] <= usageStats["R1"])
                {this.templist[index]["R1"] = "red"}
                else {this.templist[index]["R1"] = "green"}
            } else {
              this.templist[index]["R1"] = "grey"
            }
            if ("R2" in alertLogs) {
              if (parseFloat(alertLogs["R2"]) <= usageStats["R2"])
                {this.templist[index]["R2"] = "red"}
                else {this.templist[index]["R2"] = "green"}
            } else {
              this.templist[index]["R2"] = "grey"
            }
            if ("R3" in alertLogs) {
              if (parseFloat(alertLogs["R3"]) <= usageStats["R3"])
                {this.templist[index]["R3"] = "red"}
                else {this.templist[index]["R3"] = "green"}
            } else {
              this.templist[index]["R3"] = "grey"
            }
            if ("R4" in alertLogs) {
              if (parseFloat(alertLogs["R4"]) <= usageStats["R4"])
                {this.templist[index]["R4"] = "red"}
                else {this.templist[index]["R4"] = "green"}
            } else {
              this.templist[index]["R4"] = "grey"
            }
            this.requestLog.push(this.templist[index])
          
            });

    },

    getRequest() {
      this.requestLog = []
      axios
        .get(`${API_BASE_URL}/kiosk`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.templist = resp.data
          for (let index = 0; index < this.templist.length; index++) {
            const element = this.templist[index];
            this.calStats(element,index)
          }
        });
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },

    saveKiosk(kiosk, conf) {
      console.log(conf);
      let payload = { details: JSON.stringify(conf), name: kiosk.name };
      let res = axios.put(`${API_BASE_URL}/kiosk/${kiosk.mac}`, payload);
      let data = res.data;
      this.editDevice = false;
      this.getRequest();
    },

    async getHours(mac) {},

    deleteKiosk(ID) {
      if (confirm("Do you really want to delete this kiosk?")) {
        axios
          .delete(`${API_BASE_URL}/kiosk/${ID}`, {
            headers: {
              accept: "application/json",
              "x-access-token": this.token.accessToken,
            },
          })
          .then((resp) => {
            this.snackbar = true;
            this.getRequest();
          });
      }
    },
  },
};
</script>
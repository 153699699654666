import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from "./store";
import './assets/app.scss'
import VueApexCharts from 'vue-apexcharts'
import VueCookies from 'vue-cookies';
import VueJsonToCsv from 'vue-json-to-csv'

Vue.use(VueCookies);
Vue.use(VueApexCharts);
Vue.use(VueJsonToCsv);
Vue.component('vue-json-to-csv', VueJsonToCsv);
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
